import React from "react";
import "./QualityAssurance.css";
import { Link } from "react-router-dom";

const QualityAssurance = () => {
  return (
    <div className="qa-container">
      {/* Título da Página */}
      <h1 className="qa-title">Quality Assurance</h1>

      {/* Card de Conteúdo */}
      <div className="qa-card">
        <p>
          Nessa etapa do projeto verificamos se o fluxo está ocorrendo corretamente, se todos os cenários de uso previsto foram
          implementados. Além disso, fazemos uma verificação separada e dedicada para as três camadas de projeto digital:
        </p>

        <ul>
          <li>Camada de Informação;</li>
          <li>Camada de Comportamento;</li>
          <li>Camada de Formatação.</li>
        </ul>

        {/* Botão com Link */}
        <Link to="/contato" className="qa-button">
          Quer Saber Mais?
        </Link>
      </div>
    </div>
  );
};

export default QualityAssurance;
