import React from "react";
import "./Servicos.css";
import { Link } from "react-router-dom";
import card1 from "../assets/card1.jpg";
import card2 from "../assets/card2.jpg";
import card3 from "../assets/card3.jpg";
import card4 from "../assets/card4.jpg";
import card5 from "../assets/card5.jpg";
import card6 from "../assets/card6.jpg";
import card7 from "../assets/card7.jpg";
import card8 from "../assets/card8.jpg";
import card9 from "../assets/card9.jpg";

const Servicos = () => {
  const services = [
    { title: "Descoberta & Imersão", link: "/descoberta", image: card1 },
    { title: "Definindo o MLP", link: "/definindo-mlp", image: card2 },
    { title: "Estrutura & UX Design", link: "/estrutura-ux-design", image: card3 },
    { title: "User Interface Design", link: "/user-interface-design", image: card4 },
    { title: "Codando e Implementando", link: "/codando-e-implementando", image: card5 },
    { title: "Quality Assurance", link: "/quality-assurance", image: card6 },
    { title: "Business Intelligence", link: "/business-intelligence", image: card7 },
    { title: "Tech Lead", link: "/tech-lead", image: card8 },
    { title: "Arquiteto de Soluções", link: "/arquiteto-de-solucoes", image: card9 },
  ];

  return (
    <section id="servicos" className="servicos-section">
      <div className="container">
        <h2 className="section-title">NOSSOS SERVIÇOS</h2>

        {/* Grid para Desktop */}
        <div className="servicos-grid d-none d-md-grid">
          {services.map((service, index) => (
            <div
              className="servico-card"
              key={index}
              style={{ backgroundImage: `url(${service.image})` }}
            >
              <div className="servico-title-container">
                <h3 className="servico-title">{service.title}</h3>
              </div>
              <div className="saiba-mais-container">
                <Link to={service.link} className="saiba-mais-btn">
                  Saiba Mais
                </Link>
              </div>
            </div>
          ))}
        </div>

        {/* Carousel para Mobile */}
        <div
          id="carouselServicos"
          className="carousel slide d-md-none"
          data-bs-ride="carousel"
        >
          <div className="carousel-inner">
            {services.map((service, index) => (
              <div
                className={`carousel-item ${index === 0 ? "active" : ""}`}
                key={index}
              >
                <div
                  className="servico-card"
                  style={{ backgroundImage: `url(${service.image})` }}
                >
                  <div className="servico-title-container">
                    <h3 className="servico-title">{service.title}</h3>
                  </div>
                  <div className="saiba-mais-container">
                    <Link to={service.link} className="saiba-mais-btn">
                      Saiba Mais
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
          {/* Controles do Carousel */}
          <button
            className="carousel-control-prev"
            type="button"
            data-bs-target="#carouselServicos"
            data-bs-slide="prev"
          >
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next"
            type="button"
            data-bs-target="#carouselServicos"
            data-bs-slide="next"
          >
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </div>
    </section>
  );
};

export default Servicos;
