import './assets/styles/main.scss';
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "./components/Header";
import FullBanner from "./components/FullBanner";
import QuemSomos from "./components/QuemSomos";
import Clientes from "./components/Clientes";
import Servicos from "./components/Servicos";
import Footer from "./components/Footer";
import Descoberta from "./components/Descoberta";
import DefinindoMLP from "./components/DefinindoMLP"; 
import EstruturaUXDesign from "./components/EstruturaUXDesign";
import UserInterfaceDesign from "./components/UserInterfaceDesign";
import CodandoEImplementando from "./components/CodandoEImplementando";
import QualityAssurance from "./components/QualityAssurance";
import BusinessIntelligence from "./components/BusinessIntelligence";
import TechLead from "./components/TechLead";
import ArquitetoDeSolucoes from "./components/ArquitetoDeSolucoes";
import Contato from "./components/Contato";
import PlataformasQueTrabalhamos from "./components/PlataformasQueTrabalhamos";


function App() {
  return (
    <Router>
      <Header />
      <Routes>
        {/* Página Principal */}
        <Route
          path="/"
          element={
            <>
              <main>
                <FullBanner />
                <QuemSomos />
                <Clientes />
                <Servicos />
                <PlataformasQueTrabalhamos />

              </main>
              <Footer />
            </>
          }
        />
        {/* Página Descoberta */}
        <Route path="/descoberta" element={<Descoberta />} />
        <Route path="/definindo-mlp" element={<DefinindoMLP />} />
        <Route path="/estrutura-ux-design" element={<EstruturaUXDesign />} />
        <Route path="/user-interface-design" element={<UserInterfaceDesign />} />
        <Route path="/codando-e-implementando" element={<CodandoEImplementando />} />
        <Route path="/quality-assurance" element={<QualityAssurance />} />
        <Route path="/business-intelligence" element={<BusinessIntelligence />} />
        <Route path="/tech-lead" element={<TechLead />} />
        <Route path="/arquiteto-de-solucoes" element={<ArquitetoDeSolucoes />} />
        <Route path="/contato" element={<Contato />} />
      </Routes>
    </Router>
  );
}

export default App;
