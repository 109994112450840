import React from "react";
import "./Clientes.css";
import seara from "../assets/seara.png";
import searagourmet from "../assets/searagourmet.png";
import jbs from "../assets/jbs.png";
import ricca from "../assets/ricca.png";
import gradina from "../assets/gradina.png";
import fioebeleza from "../assets/fioebeleza.png";

const clientes = [
  { image: seara, name: "Seara" },
  { image: searagourmet, name: "Seara Gourmet" },
  { image: jbs, name: "JBS" },
  { image: ricca, name: "Ricca" },
  { image: gradina, name: "Gradina" },
  { image: fioebeleza, name: "Fio e Beleza" },
];

const Clientes = () => {
  return (
    <section id="clientes" className="clientes-section">
      <div className="container">
        <h2 className="clientes-title">NOSSOS CLIENTES</h2>

        {/* Grid para Desktop */}
        <div className="clientes-grid d-none d-md-grid">
          {clientes.map((cliente, index) => (
            <div className="cliente-card" key={index}>
              <img
                src={cliente.image}
                alt={cliente.name}
                className="cliente-image"
              />
            </div>
          ))}
        </div>

        {/* Carousel para Mobile */}
        <div
          id="carouselClientes"
          className="carousel slide d-md-none"
          data-bs-ride="carousel"
        >
          <div className="carousel-inner">
            {clientes.map((cliente, index) => (
              <div
                className={`carousel-item ${index === 0 ? "active" : ""}`}
                key={index}
              >
                <div className="cliente-card">
                  <img
                    src={cliente.image}
                    alt={cliente.name}
                    className="cliente-image"
                  />
                </div>
              </div>
            ))}
          </div>
          {/* Controles do Carousel */}
          <button
            className="carousel-control-prev"
            type="button"
            data-bs-target="#carouselClientes"
            data-bs-slide="prev"
          >
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next"
            type="button"
            data-bs-target="#carouselClientes"
            data-bs-slide="next"
          >
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </div>
    </section>
  );
};

export default Clientes;
