import React from "react";
import "./EstruturaUXDesign.css";
import { Link } from "react-router-dom";

const EstruturaUXDesign = () => {
  return (
    <div className="ux-container">
      {/* Título da Página */}
      <h1 className="ux-title">Estrutura & UX Design</h1>

      {/* Card de Conteúdo */}
      <div className="ux-card">
        <h2 className="ux-subtitle">Modelo Conceitual</h2>
        <p>
          Representação macro de como o projeto será do ponto de vista conceitual, sem entrar em detalhes e complexidades.
        </p>

        <h2 className="ux-subtitle">Wireframes</h2>
        <p>
          A primeira etapa da concepção do produto. No wireframe temos desenho básico, como um esqueleto, demonstrando de forma
          direta e sem intervenções visuais (como cores e imagens) como a interface final será, de acordo com as especificações
          relatadas.
        </p>

        {/* Botão com Link */}
        <Link to="/contato" className="ux-button">
          Quer Saber Mais?
        </Link>
      </div>
    </div>
  );
};

export default EstruturaUXDesign;
