import React, { useState } from "react";
import "./Footer.css";
import logo from "../assets/logofooter.png";
import { FaInstagram, FaFacebook, FaTwitter } from "react-icons/fa";

const Footer = () => {
  const [collapseState, setCollapseState] = useState({
    institucional: false,
    clientes: false,
    servicos: false,
    contato: false,
  });

  const toggleCollapse = (key) => {
    setCollapseState((prevState) => ({
      ...prevState,
      [key]: !prevState[key],
    }));
  };

  const servicesPart1 = [
    "Descoberta & Imersão",
    "Definindo o MLP",
    "Estrutura & UX Design",
    "User Interface Design",
  ];

  const servicesPart2 = [
    "Codando e Implementando",
    "Quality Assurance",
    "Business Intelligence",
    "Tech Lead",
    "Arquiteto de Soluções",
  ];

  return (
    <>
      <footer className="footer">
        <div className="container footer-grid">
          {/* Coluna 1: Logo */}
          <div className="footer-column">
            <img src={logo} alt="Growe Logo" className="footer-logo" />
          </div>

          {/* Coluna 2: Institucional */}
          <div className="footer-column">
            <h4
              className="footer-title"
              onClick={() => toggleCollapse("institucional")}
              aria-expanded={collapseState.institucional}
            >
              Institucional{" "}
              <span className="toggle-icon">
                {collapseState.institucional ? "-" : "+"}
              </span>
            </h4>
            <div
              id="collapseInstitucional"
              className={`footer-content ${
                collapseState.institucional ? "show" : "collapse"
              }`}
            >
              <a href="#quem-somos">Quem Somos</a>
            </div>
          </div>

          {/* Coluna 3: Clientes */}
          <div className="footer-column">
            <h4
              className="footer-title"
              onClick={() => toggleCollapse("clientes")}
              aria-expanded={collapseState.clientes}
            >
              Clientes{" "}
              <span className="toggle-icon">
                {collapseState.clientes ? "-" : "+"}
              </span>
            </h4>
            <div
              id="collapseClientes"
              className={`footer-content ${
                collapseState.clientes ? "show" : "collapse"
              }`}
            >
              <a href="#clientes">Nossos Clientes</a>
            </div>
          </div>

          {/* Coluna 4: Serviços */}
          <div className="footer-column">
            <h4
              className="footer-title"
              onClick={() => toggleCollapse("servicos")}
              aria-expanded={collapseState.servicos}
            >
              Serviços{" "}
              <span className="toggle-icon">
                {collapseState.servicos ? "-" : "+"}
              </span>
            </h4>
            <div
              id="collapseServicos"
              className={`footer-content ${
                collapseState.servicos ? "show" : "collapse"
              }`}
            >
              <div className="footer-links">
                {servicesPart1.map((service, index) => (
                  <div key={`part1-${index}`}>
                    <a href="#servicos">{service}</a>
                  </div>
                ))}
                {servicesPart2.map((service, index) => (
                  <div key={`part2-${index}`}>
                    <a href="#servicos">{service}</a>
                  </div>
                ))}
              </div>
            </div>
          </div>

          {/* Coluna 5: Redes Sociais e Contato */}
          <div className="footer-column">
            <h4
              className="footer-title"
              onClick={() => toggleCollapse("contato")}
              aria-expanded={collapseState.contato}
            >
              Redes Sociais{" "}
              <span className="toggle-icon">
                {collapseState.contato ? "-" : "+"}
              </span>
            </h4>
            <div
              id="collapseContato"
              className={`footer-content ${
                collapseState.contato ? "show" : "collapse"
              }`}
            >
              <div className="footer-social-icons">
                <a
                  href="https://instagram.com/growe_ag"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaInstagram />
                </a>
                <a
                  href="https://facebook.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaFacebook />
                </a>
                <a
                  href="https://twitter.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaTwitter />
                </a>
              </div>
              <div>
                <p>Email: contato@groweag.com</p>
                <p>Telefone: 11 99994-7130</p>
                <p>WhatsApp: 11 99994-7130</p>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <div className="footer-bottom">
        <p>Agência Growe - 2024 - Todos os Direitos Reservados</p>
      </div>
    </>
  );
};

export default Footer;
