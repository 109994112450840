import React from "react";
import "./DefinindoMLP.css";
import { Link } from "react-router-dom";

const DefinindoMLP = () => {
  return (
    <div className="mlp-container">
      {/* Título da Página */}
      <h1 className="mlp-title">Definindo o MLP</h1>

      {/* Card de Conteúdo */}
      <div className="mlp-card">
        <h2 className="mlp-subtitle">MLP (minimum lovable product)</h2>
        <p>
          A ideia é construir um projeto que tenha o time do mercado que a empresa precisa, mas também as funcionalidades para
          atender toda jornada do usuário.
        </p>
        <p>
          A diferença entre o MVP tradicional e o MLP é que entendemos que um produto mínimo viável não deve apenas atuar na
          camada funcional, mas ele também tem que atender as camadas emocionais e de confiança do nosso usuário.
        </p>

        {/* Botão com Link */}
        <Link to="/contato" className="mlp-button">
          Quer Saber Mais?
        </Link>
      </div>
    </div>
  );
};

export default DefinindoMLP;
