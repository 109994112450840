import React from "react";
import "./ArquitetoDeSolucoes.css";
import { Link } from "react-router-dom";

const ArquitetoDeSolucoes = () => {
  return (
    <div className="as-container">
      {/* Título da Página */}
      <h1 className="as-title">Arquiteto de Soluções</h1>

      {/* Card de Conteúdo */}
      <div className="as-card">
        <p>
          O papel da arquitetura de soluções é o de projetar soluções conforme as demandas de uma empresa. Para isso, utiliza
          respostas inéditas e conta com o auxílio de artifícios tecnológicos próprios, implementados com os recursos e modelos
          padrões da corporação.
        </p>
        <p>
          É preciso idealizar e estruturar sistemas com um alto grau de complexidade ou de funções muito específicas, é o arquiteto
          de soluções quem coloca em prática. E, entre suas atuações, podemos citar ainda:
        </p>

        <ul>
          <li>Realizar o planejamento e produção de sistemas, serviços, produtos, sites e softwares;</li>
          <li>Fazer a manutenção e acompanhamento de serviços;</li>
          <li>
            Aplicar uma análise especializada para as potencialidades de aprimoramento e aperfeiçoamento dos recursos.
          </li>
        </ul>

        {/* Botão com Link */}
        <Link to="/contato" className="as-button">
          Quer Saber Mais?
        </Link>
      </div>
    </div>
  );
};

export default ArquitetoDeSolucoes;
