import React from "react";
import "./FullBanner.css";
import fullBanner from "../assets/fullbanner.jpg";
import fullBannerMobile from "../assets/fullbannermobile.jpg";

const FullBanner = () => {
  return (
    <section className="full-banner">
      {/* Banner Desktop */}
      <img
        src={fullBanner}
        alt="Full Banner Desktop"
        className="full-banner-desktop"
      />

      {/* Banner Mobile */}
      <img
        src={fullBannerMobile}
        alt="Full Banner Mobile"
        className="full-banner-mobile"
      />
    </section>
  );
};

export default FullBanner;
