import React from "react";
import "./TechLead.css";
import { Link } from "react-router-dom";

const TechLead = () => {
  return (
    <div className="tl-container">
      {/* Título da Página */}
      <h1 className="tl-title">Tech Lead</h1>

      {/* Card de Conteúdo */}
      <div className="tl-card">
        <p>
          O líder técnico de um squad de tecnologia precisa conhecer bem os membros para distribuir as prioridades do backlog,
          explorando as habilidades individuais.
        </p>
        <p>
          Para fazer isso, ele cria o roadmap e o pipeline, que são as ferramentas de orientação para seguir com o desenvolvimento do
          produto de forma automatizada e mais eficiente.
        </p>
        <p>
          Ele é um mentor, que busca melhorar constantemente o produto e os processos, mas busca principalmente a evolução do time,
          individualmente e no coletivo.
        </p>
        <p>
          O Tech Lead é quem define as tecnologias e a arquitetura do produto, além de ser o principal responsável por prever,
          identificar e corrigir erros, por ser o profissional mais experiente.
        </p>

        {/* Botão com Link */}
        <Link to="/contato" className="tl-button">
          Quer Saber Mais?
        </Link>
      </div>
    </div>
  );
};

export default TechLead;
