import React from "react";
import "./CodandoEImplementando.css";
import { Link } from "react-router-dom";

const CodandoEImplementando = () => {
  return (
    <div className="ci-container">
      {/* Título da Página */}
      <h1 className="ci-title">Codando e Implementando</h1>

      {/* Card de Conteúdo */}
      <div className="ci-card">
        <h2 className="ci-subtitle">Front-End e Back-End</h2>
        <p>
          Chegou a hora de colocar as mãos aos códigos. Utilizamos técnicas de desenvolvimento front-end que levam em conta todas
          as premissas de SEO, velocidade de carregamento e diversas outras. Somos especialistas em traduzir projetos de UX
          deixando o Look’n Feel extremamente próximo ao layout.
        </p>

        <h2 className="ci-subtitle">Implantação na plataforma</h2>
        <p>
          Integrações e Front-End Prontos, vamos para a implantação dos códigos dentro da plataforma. Somos especialistas em
          diversas plataformas.
        </p>

        {/* Botão com Link */}
        <Link to="/contato" className="ci-button">
          Quer Saber Mais?
        </Link>
      </div>
    </div>
  );
};

export default CodandoEImplementando;
