import React from "react";
import "./Header.css";
import logo from "../assets/logo.png";
import { useNavigate, useLocation } from "react-router-dom";

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleNavigation = (path) => {
    if (location.pathname !== "/") {
      // Se não estiver na home, redireciona para a home
      navigate("/");
    }
    // Aguarda a navegação para rolar até a seção
    setTimeout(() => {
      const target = document.querySelector(path);
      if (target) {
        target.scrollIntoView({ behavior: "smooth" });
      }
    }, 100);

    // Fecha o menu no mobile
    const navbar = document.getElementById("navbarNav");
    if (navbar && navbar.classList.contains("show")) {
      navbar.classList.remove("show");
    }
  };

  // Função para navegação do link "Chame a Growe"
  const handleContactNavigation = () => {
    navigate("/contato");
    // Fecha o menu no mobile
    const navbar = document.getElementById("navbarNav");
    if (navbar && navbar.classList.contains("show")) {
      navbar.classList.remove("show");
    }
  };

  return (
    <header className="header-container">
      <nav className="navbar navbar-expand-lg navbar-dark bg-black">
        <div className="container">
          {/* Logo */}
          <a className="navbar-brand" href="/">
            <img src={logo} alt="Growe Logo" className="logo" />
          </a>

          {/* Toggle Button for Mobile */}
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          {/* Navbar Links */}
          <div
            className="collapse navbar-collapse justify-content-center"
            id="navbarNav"
          >
            <ul className="navbar-nav">
              <li className="nav-item">
                <button
                  className="nav-link btn-link"
                  onClick={() => handleNavigation("#quem-somos")}
                >
                  Quem Somos
                </button>
              </li>
              <li className="nav-item">
                <button
                  className="nav-link btn-link"
                  onClick={() => handleNavigation("#clientes")}
                >
                  Clientes
                </button>
              </li>
              <li className="nav-item">
                <button
                  className="nav-link btn-link"
                  onClick={() => handleNavigation("#servicos")}
                >
                  Serviços
                </button>
              </li>
              <li className="nav-item">
                <button
                  className="nav-link btn-link"
                  onClick={() => handleNavigation("#plataformas")}
                >
                  Plataformas
                </button>
              </li>
              <li className="nav-item">
                <button
                  className="nav-link btn-highlight"
                  onClick={handleContactNavigation}
                >
                  Chame a Growe
                </button>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
