import React, { useState } from "react";
import emailjs from "emailjs-com";
import "./Contato.css";

const Contato = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [isPopupVisible, setPopupVisible] = useState(false);

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs
    .send(
      "service_cgwlhtv", // Substitua pelo seu Service ID
      "template_u5l2uua", // Substitua pelo seu Template ID
      formData,
      "FKIQ668Wm9vc3LUkI" // Substitua pelo seu Public Key
    )
      .then(
        () => {
          setPopupVisible(true); // Exibe o popup de sucesso
          setFormData({ name: "", email: "", message: "" }); // Reseta o formulário
        },
        (err) => {
          console.error("Failed to send message:", err);
        }
      );
  };

  const closePopup = () => {
    setPopupVisible(false); // Fecha o popup
  };

  return (
    <div className="contato-container">
      {/* Título da Página */}
      <h1 className="contato-title">Contato</h1>

      <div className="contato-content">
        {/* Formulário de Contato */}
        <form className="contato-form" onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="name">Nome</label>
            <input
              type="text"
              id="name"
              placeholder="Digite seu nome"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              placeholder="Digite seu email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="message">Mensagem</label>
            <textarea
              id="message"
              rows="5"
              placeholder="Digite sua mensagem"
              value={formData.message}
              onChange={handleChange}
              required
            />
          </div>

          <button type="submit" className="contato-button">
            Enviar
          </button>
        </form>

        {/* Card com Dados de Contato */}
        <div className="contato-card">
          <p>
            <strong>Email:</strong> contato@groweag.com
          </p>
          <p>
            <strong>Telefone:</strong> 11 99994-7130
          </p>
          <p>
            <strong>WhatsApp:</strong> 11 99994-7130
          </p>
        </div>
      </div>

      {/* Popup de Sucesso */}
      {isPopupVisible && (
        <div className="popup-overlay">
          <div className="popup">
            <p>Mensagem enviada com sucesso!</p>
            <button onClick={closePopup} className="popup-button">
              Fechar
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Contato;
