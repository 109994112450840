import React from "react";
import "./QuemSomos.css";
import quemSomos from "../assets/quemsomos.jpg";

const QuemSomos = () => {
  return (
    <section id="quem-somos" className="quem-somos">
      <div className="container quem-somos-container">
        <div className="quem-somos-image">
          {/* Imagem Temporária */}
          <img
            src={quemSomos}
            alt="Ilustração sobre Quem Somos"
          />
        </div>
        <div className="quem-somos-content">
          <h1 className="section-title">Quem Somos</h1>
          <p className="quem-somos-text">
            A Growe é uma agência especializada em desenvolvimento digital.
            Desde o início, nossa missão tem sido preparar empresas para enfrentar desafios de alta performance, proporcionando
            soluções eficazes e inovadoras. Valorizamos a transparência, inovação, colaboração, comprometimento e adaptabilidade em
            tudo o que nos propomos a fazer. Estes valores são a base do nosso ecossistema, que não se limita a ser uma agência, mas
            sim um sistema que gera valor ao seu negócio.
          </p>
          <p className="quem-somos-text">
            Entendemos profundamente as dores e desafios enfrentados pelas lojas online e trabalhamos incansavelmente para trazer
            melhorias significativas que resultem em prosperidade e crescimento. Nosso compromisso é único e claro: entregar resultados.
            Acreditamos firmemente que um atendimento personalizado e a confiança mútua são essenciais para construir um relacionamento
            duradouro e próspero com nossos clientes. Nosso nome já diz muita coisa: CRESÇA CONOSCO.
          </p>
          <p className="quem-somos-text">
            Nosso objetivo é oferecer um serviço diferenciado, atuando como parceiros estratégicos para o sucesso de negócios online.
            Estamos dedicados a compreender as necessidades específicas do seu negócio e a implementar soluções que gerem impacto real
            e mensurável.
          </p>
          <p className="quem-somos-text">
            Venha fazer parte do nosso ecossistema e experimente a diferença que a verdadeira dedicação e expertise podem fazer para o
            seu negócio.
          </p>
        </div>
      </div>
    </section>
  );
};

export default QuemSomos;
