import React from "react";
import "./Descoberta.css";
import { Link } from "react-router-dom";

const Descoberta = () => {
  return (
    <div className="descoberta-container">
      {/* Título da Página */}
      <h1 className="descoberta-title">Descoberta & Imersão</h1>

      {/* Card de Conteúdo */}
      <div className="descoberta-card">
        <h2 className="descoberta-subtitle">Análise e organização de desejos</h2>
        <p>
          Começamos fazendo reuniões para descoberta de todos os desejos da companhia em relação ao projeto de vendas online.
        </p>

        <h2 className="descoberta-subtitle">Encontro com executivos envolvidos</h2>
        <p>
          Para que todas as unidades de negócios sejam envolvidas, escutamos os desejos e preocupações de todos os executivos
          envolvidos no projeto e depois organizamos as principais entregas de acordo com o impacto de cada uma das solicitações.
        </p>

        {/* Botão com Link */}
        <Link to="/contato" className="descoberta-button">
          Quer Saber Mais?
        </Link>
      </div>
    </div>
  );
};

export default Descoberta;
