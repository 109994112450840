import React from "react";
import "./BusinessIntelligence.css";
import { Link } from "react-router-dom";

const BusinessIntelligence = () => {
  return (
    <div className="bi-container">
      {/* Título da Página */}
      <h1 className="bi-title">Business Intelligence</h1>

      {/* Card de Conteúdo */}
      <div className="bi-card">
        <p>
          O business intelligence (BI) combina análise empresarial, mineração de dados, visualização de dados,
          ferramentas/infraestrutura de dados e práticas recomendadas para ajudar as organizações a tomar decisões
          impulsionadas por dados. Na prática, você sabe que tem o business intelligence moderno quando possui uma visão
          abrangente dos dados da sua organização e usa esses dados para gerar mudanças positivas, eliminar a ineficiência
          e se adaptar rapidamente às mudanças no mercado ou na cadeia de fornecimento.
        </p>

        <ul>
          <li>Mineração de dados</li>
          <li>Geração de relatórios</li>
          <li>Análise descritiva</li>
          <li>Consultas</li>
          <li>Visualização de dados</li>
          <li>Análise visual</li>
          <li>Preparação de dados</li>
        </ul>

        {/* Botão com Link */}
        <Link to="/contato" className="bi-button">
          Quer Saber Mais?
        </Link>
      </div>
    </div>
  );
};

export default BusinessIntelligence;
