import React from "react";
import "./PlataformasQueTrabalhamos.css";
import vtex from "../assets/vtex.png";
import salesforce from "../assets/salesforce.png";
import wordpress from "../assets/wordpress.png";
import shopify from "../assets/shopify.png";
import lojaintegrada from "../assets/lojaintegrada.png";
import nuvemshop from "../assets/Nuvemshop.png";
import wake from "../assets/wake.png";
import google from "../assets/google.png";
import meta from "../assets/meta.png";
import bling from "../assets/bling.png";

const plataformas = [
  { image: vtex, description: "Serviço 1\nServiço 2\nServiço 3" },
  { image: salesforce, description: "Serviço 1\nServiço 2\nServiço 3" },
  { image: wordpress, description: "Serviço 1\nServiço 2\nServiço 3" },
  { image: shopify, description: "Serviço 1\nServiço 2\nServiço 3" },
  { image: lojaintegrada, description: "Serviço 1\nServiço 2\nServiço 3" },
  { image: nuvemshop, description: "Serviço 1\nServiço 2\nServiço 3" },
  { image: wake, description: "Serviço 1\nServiço 2\nServiço 3" },
  { image: google, description: "Serviço 1\nServiço 2\nServiço 3" },
  { image: meta, description: "Serviço 1\nServiço 2\nServiço 3" },
  { image: bling, description: "Serviço 1\nServiço 2\nServiço 3" },
];

const PlataformasQueTrabalhamos = () => {
  return (
    <section id="plataformas" className="plataformas-section">
      <div className="container">
        <h2 className="section-title">PLATAFORMAS QUE TRABALHAMOS</h2>

        {/* Grid para Desktop */}
        <div className="plataformas-grid d-none d-md-grid">
          {plataformas.map((plataforma, index) => (
            <div className="plataforma-card" key={index}>
              <img
                src={plataforma.image}
                alt={`Logo ${plataforma.title}`}
                className="plataforma-image"
              />
              {/* <p className="plataforma-description">
                {plataforma.description.split("\n").map((line, i) => (
                  <span key={i}>
                    {line}
                    <br />
                  </span>
                ))}
              </p> */}
            </div>
          ))}
        </div>

        {/* Carousel para Mobile */}
        <div
  id="carouselPlataformas"
  className="carousel slide d-md-none"
  data-bs-ride="carousel"
>
  <div className="carousel-inner">
    {plataformas.map((plataforma, index) => (
      <div
        className={`carousel-item ${index === 0 ? "active" : ""}`}
        key={index}
      >
        <div className="plataforma-card">
          <img
            src={plataforma.image}
            alt={`Logo ${plataforma.title}`}
            className="plataforma-image"
          />
          {/* <p className="plataforma-description">
            {plataforma.description.split("\n").map((line, i) => (
              <span key={i}>
                {line}
                <br />
              </span>
            ))}
          </p> */}
        </div>
      </div>
    ))}
  </div>
  <button
    className="carousel-control-prev"
    type="button"
    data-bs-target="#carouselPlataformas"
    data-bs-slide="prev"
  >
    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Previous</span>
  </button>
  <button
    className="carousel-control-next"
    type="button"
    data-bs-target="#carouselPlataformas"
    data-bs-slide="next"
  >
    <span className="carousel-control-next-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Next</span>
  </button>
</div>

      </div>
    </section>
  );
};

export default PlataformasQueTrabalhamos;
