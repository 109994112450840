import React from "react";
import "./UserInterfaceDesign.css";
import { Link } from "react-router-dom";

const UserInterfaceDesign = () => {
  return (
    <div className="ui-container">
      {/* Título da Página */}
      <h1 className="ui-title">User Interface Design</h1>

      {/* Card de Conteúdo */}
      <div className="ui-card">
        <h2 className="ui-subtitle">Design Sprint</h2>
        <p>
          Diferente de encomendar um trabalho para uma consultoria, no Design Sprint as equipes da empresa/produto criam juntas. A
          ideia é comprimir em uma semana os meses de discussões intermináveis e idas e vindas de um projeto. Quem nunca passou por
          isso? Ao final da semana, temos um caminho claro a seguir com a UX.
        </p>

        <h2 className="ui-subtitle">Criação do Layout</h2>
        <p>
          É nessa fase que aplica-se toda a finalização de arte dentro da interface, seguindo a identidade visual da marca, as
          referências da etapa Mood Board e demais elementos visuais, como cores, fontes e contrastes, que ajudarão na experiência e
          na usabilidade do projeto.
        </p>

        {/* Botão com Link */}
        <Link to="/contato" className="ui-button">
          Quer Saber Mais?
        </Link>
      </div>
    </div>
  );
};

export default UserInterfaceDesign;
